.chatGrid {
  @apply grid gap-x-5 items-end fixed bottom-3 sm:bottom-10 right-3 sm:right-10 z-50;
  grid-template-columns: auto auto;
}

.chatButton {
  @apply flex justify-center items-center w-[45px] h-[45px] text-white rounded-full bg-[#1D1C1B] border-solid border-[2px] border-white shadow-md;
}

.bubbleContainer {
  @apply inline-block relative rounded-t-xl rounded-bl-xl px-2 py-2 mb-3 bg-white shadow-md;
  @apply sm:px-[16px] sm:py-[16px] sm:pr-[41px] sm:hidden;
}

.mobileInitialBubbleContainer {
  @apply inline-block relative rounded-t-xl rounded-bl-xl px-2 py-2 mb-3 mr-5 bg-white pr-[41px] shadow-md;
  @apply sm:px-[16px] sm:py-[16px] sm:hidden;
}

.bubbleCloseButton {
  @apply absolute top-[10px] right-[10px] cursor-pointer hidden sm:block;
}

.mobileCloseButton {
  @apply absolute top-[10px] right-[10px] cursor-pointer;
}

.chatOptions {
  @apply flex flex-col items-end;
}

.activeChatOptions {
  @apply hidden;
} 

.inactiveChatOptions {
  @apply flex;
}

.activeChatBubble {
  @apply hidden;
}

.inactiveChatBubble {
  @apply inline-block sm:block;
}

.hideInitialBubble {
  @apply hidden;
}

.showInitialBubble {
  @apply hidden sm:block;
  min-width: unset;
}

.mobileBackdrop {
  @apply fixed top-0 left-0 w-full h-full opacity-30 bg-black z-[-1];
}

.showMobileBackdrop {
  @apply block sm:hidden;
}

.hideMobileBackdrop {
  @apply hidden;
}